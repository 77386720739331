<template>
  <div class="kw-tabs-wrapper-content">
    <h3>{{ spot.name }}</h3>
    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("SPOTS.SPOT_NAME") }}</dt>
          <dd>
            {{ spot.name }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("SPOTS.SPOT_NUMBER") }}</dt>
          <dd>
            <div v-html="spot.code"></div>
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.CATEGORY") }}</dt>
          <dd>
            <spot-category
              v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SPOT_CATEGORIES)"
              :spotCategory="spot.category"
            />
            <span v-else>{{ spot.category?.name }}</span>
          </dd>
        </dl>
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_SPOT_CATEGORIES)"
        >
          <dt>{{ $t("SPOTS.SPOT_TYPE") }}</dt>
          <dd>
            <spot-type :spotType="spot.spotType" />
          </dd>
        </dl>
        <dl class="row" v-if="spot.excerpt">
          <dt>{{ $t("COMMON.COMMENT") }}</dt>
          <dd>
            <div v-html="spot.excerpt"></div>
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.STATUS") }}</dt>
          <dd>
            {{ $t(`SPOTS.SPOT_STATUS_${spot.status}`) }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.PRICE") }}</dt>
          <dd>
            {{ spot.unit_price }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("SPOTS.SPOT_CAPACITY") }}</dt>
          <dd>
            {{ spot.capacity }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("SPOTS.SPOT_ADULT_CAPACITY") }}</dt>
          <dd>
            {{ spot.adults_capacity }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("SPOTS.SPOT_CHILDREN_CAPACITY") }}</dt>
          <dd>
            {{ spot.children_capacity }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("SPOTS.SPOT_PETS_CAPACITY") }}</dt>
          <dd>
            {{ spot.pets_capacity }}
          </dd>
        </dl>

        <dl v-if="islandFormType" class="row">
          <dt>{{ $t("SPOTS.EQUIPMENT_LENGTH") }}</dt>
          <dd>
            {{ spot.equipment_length }}
          </dd>
        </dl>

        <dl v-if="islandFormType" class="row">
          <dt>{{ $t("SPOTS.SPOT_AREA") }}</dt>
          <dd>{{ spot.spot_area }} ({{ $t("COMMON.UNIT_SQUARE_FOOT") }})</dd>
        </dl>

        <dl v-if="islandFormType" class="row">
          <dt>{{ $t("SPOTS.PASSENGER_EXTENSIONS") }}</dt>
          <dd>
            {{ spot.passenger_extensions }}
          </dd>
        </dl>

        <dl v-if="islandFormType" class="row">
          <dt>{{ $t("SPOTS.DRIVER_EXTENSIONS") }}</dt>
          <dd>
            {{ spot.driver_extensions }}
          </dd>
        </dl>

        <dl v-if="islandFormType" class="row">
          <dt>{{ $t("SPOTS.ALLOWED_EQUIPMENT") }}</dt>
          <dd>
            {{ spot.allowed_equipment }}
          </dd>
        </dl>

        <dl v-if="isPropertyFormType" class="row">
          <dt>{{ $t("SPOTS.BEDS_KING_QTY") }}</dt>
          <dd>
            {{ spot.beds_king_qty }}
          </dd>
        </dl>

        <dl v-if="isPropertyFormType" class="row">
          <dt>{{ $t("SPOTS.BEDS_QUEEN_QTY") }}</dt>
          <dd>
            {{ spot.beds_queen_qty }}
          </dd>
        </dl>

        <dl v-if="isPropertyFormType" class="row">
          <dt>{{ $t("SPOTS.BEDS_DOUBLE_QTY") }}</dt>
          <dd>
            {{ spot.beds_double_qty }}
          </dd>
        </dl>

        <dl v-if="isPropertyFormType" class="row">
          <dt>{{ $t("SPOTS.BEDS_TWIN_QTY") }}</dt>
          <dd>
            {{ spot.beds_twin_qty }}
          </dd>
        </dl>

        <dl v-if="isPropertyFormType" class="row">
          <dt>{{ $t("SPOTS.BATHROOM_SHOWER_QTY") }}</dt>
          <dd>
            {{ spot.bathroom_shower_qty }}
          </dd>
        </dl>

        <dl v-if="isPropertyFormType" class="row">
          <dt>{{ $t("SPOTS.BATHROOM_BATHTUB_QTY") }}</dt>
          <dd>
            {{ spot.bathroom_bathtub_qty }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("SPOTS.SPOT_ARRIVAL_TIME") }}</dt>
          <dd>
            {{ spot.arrival_time }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("SPOTS.SPOT_DEPARTURE_TIME") }}</dt>
          <dd>
            {{ spot.departure_time }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd>
            {{ spot.created_at | moment("DD/MM/YYYY") }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_BY") }}</dt>
          <dd>{{ spot.createdBy.firstname }} {{ spot.createdBy.lastname }}</dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd>
            {{ spot.updated_at | moment("DD/MM/YYYY") }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import SpotCategory from "@/components/SpotCategory";
import SpotType from "@/components/SpotType";
import { FORM_TYPE_LAND, FORM_TYPE_PROPERTY } from "@/constants/common";

export default {
  name: "spot-view-global",

  props: ["spot"],

  components: { SpotCategory, SpotType },

  data() {
    return {};
  },

  computed: {
    islandFormType() {
      return this.selectedCategory?.form_type === FORM_TYPE_LAND;
    },
    isPropertyFormType() {
      return this.selectedCategory?.form_type === FORM_TYPE_PROPERTY;
    },
  },

  created() {},

  methods: {
    spotUpdated() {
      this.$emit("spotUpdated", true);
    },
  },

  mounted() {},

  watch: {
    spot(spot) {},
  },
};
</script>
